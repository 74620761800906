import React from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { linkRouge, palette} from "../../styles/styles";
import { Link } from "gatsby";

const Investors = (props) => {
  const { className } = props;
  const { t } = useTranslation("digitalCampus");

  return (
    <section className={className}>
      <h2>{t("investors")}</h2>
      <div className="investors">
        <StaticImage className="img" src="../../images/investors/investor1.png" alt="" />
        <StaticImage className="img" src="../../images/investors/investor2.png" alt="" />
        <Link to="/contact" className="link">{t("getOurDeck")}</Link>
      </div>
    </section>
  );
};

export default styled(Investors)`
  padding: 8% 10%;
  height: fit-content;
  background-color: ${palette.blue};
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  justify-items: center;
  grid-row-gap: 4rem;
  h2 {
    font-size: 6rem;
    color: ${palette.whiteWhite};
    text-align: center;
    height: fit-content;
  }
  .investors {
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: center;
    justify-content: center;
    grid-column-gap: 8rem;
    .img{
      width: 65%;
    }
    .link {
      ${linkRouge};
      min-width: fit-content;
      width:fit-content!important;
    }
  }
  @media(max-width: 768px) {
    padding: 10% 8%;
    .investors {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      justify-items: center;
      grid-row-gap: 2rem;
      grid-column-gap: 4rem;

      .img {
        width: 75%;
      }

      .link {
        grid-column: 1/-1;
      }
    }
  }
`;
