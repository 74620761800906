import React from "react";
import styled from "styled-components";
import Layout from "../comps/reusable/Layout";
import {graphql} from "gatsby";
import HeroSection from "../comps/reusable/HeroSection";
import SimpleParagraph from "../comps/reusable/SimpleParagraph";
import Hiring from "../comps/about-us/Hiring";
import Investors from "../comps/pledge/Investors";
import Us from "../comps/about-us/Us";

const AboutUs = () => {

  return (
    <Layout>
      <HeroSection
        data={{
          imageName: "heroUs.png",
          hasButtons: false,
        }}
        nameSpace="us"
      />
      <SimpleParagraph nameSpace="us" />
      <Hiring/>
      <Us/>
      <Investors/>
    </Layout>
  );
}

export default styled(AboutUs)``;

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;