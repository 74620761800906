import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  engineering, furry,
  humanCapital,
  leadership,
  marketing,
  operations,
} from "../../data/us";
import { getFlex, palette } from "../../styles/styles";
import Image from "../../ImageQuerys/UsImages";

const Us = (props) => {
  const { className } = props;
  const { t } = useTranslation("us");

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const oneOfUs = (data, onLeft) => {
    return (
      <>
        <div id="desktop" className="oneOfUs">
          {onLeft ? (
            <div className="imgContainer">
              <Image className="image" imageName={data.photo} alt="" />
            </div>
          ) : null}
          <div className="allText">
            <div className="firstLine">
              <h2>{data.fullName}</h2>
              <p className="post">{data.post}</p>
            </div>
            {data.quote ? <q>{data.quote}</q> : null}
            <p className="desc">{data.desc}</p>
            {data.linkedin ? (
              <a href={data.linkedin} target="_blank" className="linkedin">
                {t("linkedin")}
              </a>
            ) : null}
          </div>
          {!onLeft ? (
            <div className="imgContainer">
              <Image className="image" imageName={data.photo} alt="" />
            </div>
          ) : null}
        </div>
        {/* Mobile  */}
        <div id="mobile" className="oneOfUs">
          <div className="imgContainer">
            <Image className="photo" imageName={data.photo} alt="" />
          </div>
          <div className="allText">
            <div className="firstLine">
              <h2>{data.fullName}</h2>
              <p className="post">{data.post}</p>
            </div>
            {data.quote ? <q>{data.quote}</q> : null}
            <p className="desc">{data.desc}</p>
            {data.linkedin ? <a className="linkedin">{t("linkedin")}</a> : null}
          </div>
        </div>
      </>
    );
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <section className={className}>
      <div className="tabs">
        <h2>{t("usTitle")}</h2>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={t("leadership")} {...a11yProps(0)} />
          <Tab label={t("hr")} {...a11yProps(1)} />
          <Tab label={t("marketing")} {...a11yProps(2)} />
          <Tab label={t("operations")} />
          <Tab label={t("engineering")} />
          <Tab label={t("furry")} />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        {leadership.map((p, i) => oneOfUs(p, i % 2 === 0))}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {humanCapital.map((p, i) => oneOfUs(p, i % 2 === 0))}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {marketing.map((p, i) => oneOfUs(p, i % 2 === 0))}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {operations.map((p, i) => oneOfUs(p, i % 2 === 0))}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {engineering.map((p, i) => oneOfUs(p, i % 2 === 0))}
      </TabPanel>
      <TabPanel value={value} index={5}>
        {furry.map((p, i) => oneOfUs(p, i % 2 === 0))}
      </TabPanel>
    </section>
  );
};

export default styled(Us)`
  padding: 8% 10%;
  height: fit-content;
  @media (min-width: 768px) {
    padding: 10% 8%;
  }
  #desktop {
    @media (max-width: 768px) {
      display: none;
    }
  }
  #mobile {
    @media (min-width: 769px) {
      display: none;
    }
    .photo {
      width: 50%;
    }
  }
  .tabs {
    display: grid;
    grid-template-columns: 25% 1fr;
    grid-column-gap: 8rem;
    align-items: center;
    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-row-gap: 4rem;
      justify-items: center;
    }
    h2 {
      font-size: 4.8rem;
    }
    .MuiTab-root {
      font-size: 1.6rem;
      text-transform: capitalize;
      font-family: SofiaProMediumAz, sans-serif;
    }
    .MuiTabs-root {
      @media(max-width: 768px){
        width: 90%;
      }
    }
  }
  .panel {
    padding: 8% 0;
    height: fit-content;
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 4rem;
    @media (max-width: 1024px) {
    }
    .oneOfUs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: flex-start;
      height: max-content;
      @media (max-width: 768px) {
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        justify-items: center;
        height: fit-content;
        & > * {
          text-align: center;
        }
      }
      .imgContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        @media (max-width: 768px) {
          justify-content: center;
        }
        .image {
          max-height: 300px;
          width: 65%;
          margin: 0 !important;
          img {
            object-fit: contain !important;
          }
          @media (max-width: 768px) {
            margin: auto !important;
            width: 35%;
          }
          @media (max-width: 600px) {
            width: 55%;
          }
        }
      }
      .allText {
        padding: 4% 0;
        height: 100%;
        ${getFlex("column", "ct", "st")};
        row-gap: 1.5rem;
        @media (max-width: 768px) {
          align-items: center;
          padding: 0 0 4% 0;
          row-gap: 1rem;
        }
        .firstLine {
          ${getFlex("row", "st", "ct")};
          flex-wrap: wrap;
          column-gap: 4rem;
          @media (max-width: 768px) {
            ${getFlex("column", "ct", "ct")};
          }
          h2 {
            text-align: left;
          }
          .post {
            font-size: 2rem;
            font-family: Cooper_Medium, sans-serif;
            color: ${palette.blue};
          }
        }
        q {
          font-size: 2rem;
          width: 90%;
        }
        .desc {
          font-family: SofiaProLightAz, sans-serif;
          font-size: 2rem;
          width: 90%;
        }
        .linkedin {
          text-decoration: none;
          font-size: 2rem;
          color: ${palette.blue};
          cursor: pointer;
          margin-top: 4rem;
          font-weight: bold;
          @media (max-width: 768px) {
            margin-top: 1rem;
          }
        }
      }
    }
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      className="panel"
      role="tabpanel"
      style={{ display: value !== index ? "none" : "" }}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
