import React from "react";
import styled from "styled-components";
import { linkRouge, palette} from "../../styles/styles";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Hiring = (props) => {
  const { className } = props;
  const { t } = useTranslation("us");

  return (
    <section className={className}>
      <h2>{t("hiringTitle")}</h2>
      <p>{t("hiringDesc")}</p>
      <a
        href="https://lapieza.io/"
        target="_blank"
        rel="noreferrer"
        className="first"
      >
        {t("hiringButton")}
      </a>
    </section>
  );
};

export default styled(Hiring)`
  padding: 4% 8%;
  height: fit-content;
  background-color: ${palette.hiringGreen};
  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  grid-row-gap: 4rem;
  h2 {
    color: ${palette.whiteWhite};
    font-size: 6rem;
    width: 55%;
    text-align: center;
    line-height: 7.2rem;
  }
  p {
    font-size: 1.6rem;
    font-family: SofiaProRegularAz, sans-serif;
    line-height: 2.4rem;
    color: ${palette.whiteWhite};
    text-align: center;
    width: 65%;
  }
  a {
    ${linkRouge};
  }
  @media(max-width: 600px){
    padding: 10%;
  }
`;
