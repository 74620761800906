export const leadership = [
  {
    fullName: "Pol Morral",
    post: "CEO & Co-Founder",
    quote: "",
    desc: "Growth hacker & ambitious entrepreneur. I see myself as an open-minded person who loves travelling and getting to know new countries and cultures. I am fluent in English, Catalan, French and Spanish.",
    linkedin: "https://www.linkedin.com/in/pol-morral-dauvergne/",
    photo: "pol.png",
    // "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FFoundersPhotos%2Fpol.png?alt=media&token=09dfc011-da67-4e72-aa07-652432d6edfe",
  },
  {
    fullName: "Karen Colin",
    post: "CMO & Co-Founder",
    quote:
      "Your art is the act of taking personal responsibility, challenging the status quo, and changing people.",
    desc: "I am a young woman passionate about the digital world, entrepreneurship, social media planner, marketing & sales inbound specialist, copywriting, growth hacking, data, design, or any impulse to create. Background that allows her to understand the importance of inspiring, innovating and contributing to entrepreneurship with great social impact.",
    linkedin: "https://www.linkedin.com/in/karen-colin-casillas/",
    photo: "karen.png",
    // "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FFoundersPhotos%2Fkaren.png?alt=media&token=48a681db-3038-45a3-b0d1-f17761cf01c1",
  },
  {
    fullName: "Carlos Martinez",
    post: "CTO & Co-Founder",
    quote: "I put the “Pro” in procrastination.",
    desc: "I’m a young and ambitious former digital/identity consultant turned React Architect, Founder, and CTO - who loves to tackle large and complex problems to help companies achieve their goals and leave lasting impressions.",
    linkedin: "https://www.linkedin.com/in/juan-carlos-martinez-elizondo/",
    photo: "carlos.png",
    // "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FFoundersPhotos%2Fcarlos.png?alt=media&token=c484cf02-383b-4d14-beb7-37290150a4a8",
  },
];

export const humanCapital = [
  {
    fullName: "Andrea Carmona",
    post: "Headhunter",
    quote: "Dicen que hagas lo que ames, pero yo diría que ames lo que hagas",
    desc: "Administradora y Psicóloga con experiencia en RH y Psicoanálisis. Apasionada por la diversidad y el desarrollo humano.",
    linkedin: "",
    photo: "Andrea.png",
  },
  {
    fullName: "Cynthia Balderas",
    post: "Headhunter Intern",
    quote:
      "Haz lo que te apasiona sin olvidar el impacto que tienes en el mundo",
    desc: "Soy en psicóloga con estudios en compromiso social y liderazgo. Sé que mi pasión y misión es ayudar a las personas para que puedan vivir de forma plena a través de estrategias sustentables.",
    linkedin: "",
    photo: "Cynthia.png",
  },
  {
    fullName: "María Bongianino",
    post: "Headhunter Intern",
    quote: "Nunca se pierde el esfuerzo que ponemos, para lograr algo hermoso",
    desc: "Soy Licenciada en Recursos Humanos, convencida de la importancia de acompañar a las personas en su desarrollo y crecimiento profesional, dentro de un ambiente laboral grato que les permita explotar su potencial ya que son las piezas clave de una empresa.",
    linkedin: "",
    photo: "Daniela.png",
  },
  {
    fullName: "Alexa Villegas",
    post: "Headhunter Manager & Sales",
    quote:
      "Todo es posible con perseverancia, disciplina y amor por lo que haces",
    desc: "Lic. en Administración de Negocios Internacionales. Experiencia en Headhunting, Management de equipos y Ventas. Apasionada por encontrar el mejor talento y hacerlo funcionar.",
    linkedin: "",
    photo: "Alexa.png",
  },
  {
    fullName: "Dania Bórquez",
    post: "Headhunter & Sales Representative",
    quote:
      "Nunca es tarde para comenzar eso que te apasiona y hace feliz, solo debes creer en ti, definir una meta y trabajar hasta alcanzarla",
    desc: "Licenciada en Negocios Internacionales. Creo firmemente que todas las personas tienen un talento único y mucho que aportar a todas las personas que los rodean. Me apasiona ayudar y dar lo mejor de mi en cada cosa que hago.",
    linkedin: "",
    photo: "DaniaBorquez.png",
  },
  {
    fullName: "Dinora Gómez",
    post: "Headhunter, HR Specialist & Sales Rep",
    quote: "A ship in harbor is safe, but that is not what ships are built for",
    desc: "Psicóloga (Cognitivo-conductual) con experiencia en RH y ambientes clínicos. Me encanta aportar al desarrollo, empoderamiento y cuidado personal (sí, incluye la salud mental) de la gente.. Estoy convencida de que tener un propósito, hace placentera la vida.",
    linkedin: "",
    photo: "Dino.png",
  },
  {
    fullName: "Karina Lau",
    post: "Partnerships & Headhunter",
    quote:
      "Todos podemos hacer algo, aunque al principio parezca pequeño, para impactar positivamente la vida de alguien más",
    desc: "Apasionada de la responsabilidad social, creo firmemente que todas las personas tenemos la capacidad y responsabilidad de poner nuestro granito de arena para hacer del mundo un lugar mejor para vivir.",
    linkedin: "",
    photo: "KariLau.png",
  },
  {
    fullName: "Carolina Lopez ",
    post: "Headhunter Manager & Sales",
    quote: "Nadie es como tú y ese es tu poder",
    desc: "Soy una people person apasionada por lo que hace. Soy Psicóloga Clínica con especialidad en Cognitivo Conductual disfruto mucho mi trabajo el area de reclutamiento asi como tambien dar terapia clínica. Me encanta disfrutar la vida como es y trato siempre de dar lo mejor de mi.",
    linkedin: "",
    photo: "Carolina.png",
  },
  {
    fullName: "Erick Ramírez",
    post: "Headhunter, Sales Representative and LaPieza Academy Coordinator",
    quote: "Vive todos los días desde la mejor versión de ti mismo",
    desc: "Soy Licenciado en Admón. de Empresas y Maestro en Psicología Organizacional. Mi más grande pasión es generar bienestar para las personas y considero que las empresas pueden tener un impacto muy positivo en este punto. Disfruto hablar en público, leer, nadar y salir con mi familia.",
    linkedin: "https://www.linkedin.com/in/erickrcolunga/",
    photo: "Erick.png",
  },
  {
    fullName: "Mario Romellon",
    post: "Headhunter & Sales Development Representative",
    quote:
      "Toma todas las oportunidades que lleguen a tu vida y hagan sentido con tus valores personales",
    desc: " Licenciado en Comunicación y Medios Digitales. Nómada Digital con 3 años de experiencia en liderazgo y 1 año de experiencia en reclutamiento. Mis pasiones más grandes son las aventuras espontáneas, la música, los viajes, contar historias y causar impacto con todo lo anterior.",
    linkedin: "https://www.linkedin.com/in/mario-romellon/",
    photo: "Mario.png",
  },
  {
    fullName: "Brisa Chumba",
    post: "Headhunter Intern",
    quote: "El camino al éxito es la ACTITUD",
    desc: "Próxima Licenciada en Recursos Humanos. En mi camino profesional, me estoy desarrollado como headhunter y búsqueda de talento. Dispuesta a nuevos desafíos en el hermoso mundo de HR.",
    linkedin: "https://www.linkedin.com/in/brisa-chumba/",
    photo: "BrisaChumba.png",
  },
  {
    fullName: "María Paz",
    post: "Headhunter Intern",
    quote:
      "Si tus sueños son grandes es porque tu capacidad de lograrlos también lo es",
    desc: "Soy estudiante de Recursos Humanos, me estoy formando como Headhunter y valoro mucho el poder trabajar con distintas personas. ",
    linkedin: "https://www.linkedin.com/in/mar%C3%ADa-paz-zarza-aa3bb31a3/",
    photo: "MariaPaz.png",
  },
  {
    fullName: "Ana Rodriguez",
    post: "Headhunter Manager",
    quote: "No te detengas, hasta que estes orgullos@.",
    desc: " Licenciada en Psicología, cuenta con 4 años de experiencia en Recursos Humanos, apasionada en ayudar a las personas a encontrar el trabjo de sus sueños.",
    linkedin:
      "https://www.linkedin.com/in/karina-rodr%C3%ADguez-%E5%8D%A1%E4%B8%BD%E5%A8%9C-08115911b/",
    photo: "AnaR.png",
  },
  /*{
    fullName: "Diego Minguet",
    post: "Headhunter Intern",
    quote: "Siempre hay un camino que seguir sin importar las veces que caigas",
    desc: "Licenciado en Psicología con experiencia en Recursos Humanos y áreas clínicas. Me hace feliz conocer gente que está apasionada por algo y que persigue sus sueños.",
    linkedin: "",
    photo: "",
  },*/
  {
    fullName: "Majo Priego",
    post: "Headhunter Intern",
    quote: "What you seek is seeking you",
    desc: "Soy psicóloga clínica y organizacional y formo parte del team de headhunting. Me entusiasma aprender algo nuevo cada día y apoyar a las personas a encontrar un trabajo afín a su talento y aspiraciones. Amo viajar, conocer personas nuevas , salir de mi zona de confort y encontrar cada día una mejor versión mía.",
    linkedin: "https://www.linkedin.com/in/majosepriegoro/",
    photo: "Majo.png",
  },
  {
    fullName: "Geraldina Untermann",
    post: "Headhunter ",
    quote:
      "Proyecta metas, un destino, pero no olvides la importancia del recorrido y quienes te acompañan",
    desc: "Soy Psicóloga y parte del equipo de HeadHunting en LaPieza. Me encanta mi trabajo y conocer talento de todas partes. Amo los libros y viajar.",
    linkedin: "https://www.linkedin.com/in/geraldina-untermann-1a44131a1/",
    photo: "Geraldina.jpeg",
  },
  {
    fullName: "Arantza Gutiérrez ",
    post: "Headhunting Intern",
    quote: "We cannot become what we want by remaining what we are.",
    desc: "Soy estudiante de Ing. Industrial y de Sistemas, me considero una persona optimista y que disfruta mucho estar rodeada de gente. Me gusta retarme y crecer todos los días personal y profesionalmente.",
    linkedin:
      "https://www.linkedin.com/in/arantza-guti%C3%A9rrez-gonz%C3%A1lez-23004a171/",
    photo: "Arantza.png",
  },
  {
    fullName: "Orlando Perales",
    post: "Headhunter",
    quote: "Start wide, expand further, and never look back",
    desc: "Egresado con honores de la Lic. en Administración y Estrategia de Negocios por el Tecnológico de Monterrey, Campus Monterrey. Disfruto ayudar a las pesonas a conseguir sus objetivos profesionales, al mismo tiempo que sigo aprendiendo, capacitándome en el área de Negocios y Tecnología.  Mi pasatiempo favorito es el gimnasio así como disfrutar de buena música.",
    linkedin: "https://www.linkedin.com/in/orlandoperales/",
    photo: "Orlando.png",
  },
  {
    fullName: "Francina Comamala",
    post: "Headhunter intern",
    quote: "El mundo es de quien se lo quiere comer",
    desc: "¡Hola! Soy Fran, estudiante de comunicación, siempre en busca de aventuras y nuevos retos, mi lugar favorito es el mar y todo lo que tenga que ver con él.",
    linkedin: "https://www.linkedin.com/in/francina-comamala-garcia-499391222/",
    photo: "Francina.png",
  },
  /*{ fullName: "", post: "", quote: "", desc: "", linkedin: "", photo: "" },
  { fullName: "", post: "", quote: "", desc: "", linkedin: "", photo: "" },
  { fullName: "", post: "", quote: "", desc: "", linkedin: "", photo: "" }, */
];

export const marketing = [
  {
    fullName: "Azucena Moranchel",
    post: "Social Media & Content Manager",
    quote: "Si tienes grandes ideas, usa grandes palabras para expresarlas.",
    desc: "Comunicóloga organizacional apasionadada por los procesos creativos de cualquier estrategia, me dedico especialmente en contenidos, copywriting y planeación para redes sociales. Tengo un podcast sobre crecimiento personal y profesional, me encanta viajar y conocer culturas diferentes a la mía. ",
    linkedin: "https://www.linkedin.com/in/azucena-moranchel/",
    photo: "Azucena.png",
  },
  {
    fullName: "Vanessa Alvarado",
    post: "SEO specialist",
    quote:
      "¡Eres el autor de tu propia vida, escribe versos que te alegren los días y pinta una bella sonrisa a los demás! ",
    desc: "Estudiante e integrante del equipo de Marketing, pasión por la redacción y SEO de contenido para blogs. Me encanta leer libros de fantasía y conocer personas con distintas historias que contar.",
    linkedin: "https://www.linkedin.com/in/vanessa-paola-chapa-561737182/",
    photo: "Vanessa.png",
  },
  {
    fullName: "Sebastian Lorenzini",
    post: "Influencer Manager & TikToker",
    quote:
      "Arriésgate, no sabes cuando podrás hacer lo que tienes en mente otra vez, y si no funciona será un gran aprendizaje",
    desc: "Soy egresado de licenciatura de cinematografía y medios audiovisuales, con enfoque en diseño de producción. Soy gran fan del género del terror y los musicales. Me gusta trabajar en equipo y crear contenido audiovisual aprovechando en todo momento las tendencias para crear cosas nuevas e innovadoras. ",
    linkedin: "https://www.linkedin.com/in/sebaslorenzini/",
    photo: "Sebs.png",
  },
 /* { fullName: "", post: "", quote: "", desc: "", linkedin: "", photo: "" },
  { fullName: "", post: "", quote: "", desc: "", linkedin: "", photo: "" },
  { fullName: "", post: "", quote: "", desc: "", linkedin: "", photo: "" },
  { fullName: "", post: "", quote: "", desc: "", linkedin: "", photo: "" }, */
];

export const operations = [
  {
    fullName: "Valeria Frausto",
    post: "Candidate Experience Analyst",
    quote: "La flor que crece en la adversidad es la más hermosa de todas",
    desc: "Soy comunicóloga, con gusto por el área organizacional y la psicología. Me encanta la danza, el cine y viajar. En búsqueda de conocimientos y experiencias que complementen y sumen.",
    linkedin: "",
    photo: "ValeriaF.jpg",
  },
  {
    fullName: "Melanie Donayre",
    post: "Partnerships Intern",
    quote:
      "El ayer es historia, el mañana es un misterio y el hoy es un obsequio, por eso se llama presente",
    desc: 'Soy estudiante de Comunicación y Publicidad. Me encanta estar en contacto con diferentes marcas y conocer su propósito más allá de lo material. Creo que concentrarnos en qué somos y hacia dónde vamos, aunque sea un "misterio" es una de las mejores estrategias para ser tú, para armar cualquier proyecto y para cambiar al mundo.',
    linkedin: "https://www.linkedin.com/in/melanie-donayre-montoya-64a5951b8/",
    photo: "MelanieDonayre.jpeg",
  },
  {
    fullName: "Desiree Rodríguez",
    post: "Sales Development Intern",
    quote: "Don't be  afraid to fail, be afraid not to try",
    desc: "Soy Lic. en negocios internacionales y estudiante de la maestría en ingeniería administrativa. Soy parte del team de ventas. Me gusta viajar, hacer nuevos amigos y aprender cosas nuevas. Mis pasatiempos favoritos son tocar el piano y hacer ejercicio.",
    linkedin: "",
    photo: "desiree.png",
  },
  {
    fullName: "Pedro Neumann",
    post: "Sales & Finance Manager ",
    quote: "Destiny is not a matter of chance, is a matter of choice",
    desc:
      " Estudié Administración, me entusiasma mucho el mundo startupero y soy fan de conocer nuevos clientes y poder platicar con ellos. \n" +
      "Me encanta viajar y cocinar en mi tiempo libre. ",
    linkedin: "https://www.linkedin.com/in/pedro-neumann-l%C3%B3pez-b998b8116/",
    photo: "Pedro.png",
  },
  /* { fullName: "", post: "", quote: "", desc: "", linkedin: "", photo: "" },
  { fullName: "", post: "", quote: "", desc: "", linkedin: "", photo: "" },
*/
];

export const engineering = [
  {
    fullName: "Eduardo Romero",
    post: "React Jr",
    quote: "Si no estas creando, estas destruyendo",
    desc: " Soy desarrollador jr, y estudiante de TI, con gran pasión por la tecnología.",
    linkedin:
      "https://www.linkedin.com/in/jos%C3%A9-eduardo-romero-baltazar-275a091b9/",
    photo: "Eduardo.png",
  },
  {
    fullName: "Esther Hernández",
    post: "React Developer Intern",
    quote:
      "La inspiración no solo se plasma en poemas, pinturas o música, también se plasma en código",
    desc: "Soy Ing. Tecnologías de la Información, me encanta el hecho de tener la posibilidad  desarrollar herramientas que faciliten la vida de otros.",
    linkedin: "https://www.linkedin.com/in/celia-esther/",
    photo: "esther.jpg",
  },
  {
    fullName: "Luis Ramírez",
    post: "React Developer Intern",
    quote: "La única forma de hacer un gran trabajo es amar lo que haces",
    desc: "Ingeniero en Desarrollo de Software, soy un apasionado del conocimiento y disfruto aplicar lo que sé en la resolución de problemas. Me encanta compartir mis ideas con gente de todo tipo y ampliar mi panorama escuchando sus opiniones.",
    linkedin: "https://www.linkedin.com/in/luis-ernesto-ramirez-castillo/",
    photo: "Luis.jpg",
  },
  {
    fullName: "Pedro González",
    post: "React Developer",
    quote:
      "El respeto ... se gana, la honestidad ... se aprecia, la confianza ... se adquiere, la lealtad ... se devuelve",
    desc: "",
    linkedin: "https://www.linkedin.com/in/pedro-gza-hdz/",
    photo: "PedroG.jpeg",
  },
  {
    fullName: "Mariana Sentíes",
    post: "UX Intern",
    quote: "Carpe diem. Seize the day. Make your life extraordinary.",
    desc: "Diseñadora industrial, diseñadora gráfica y diseñadora UX. Amante de los pequeños detalles de la vida y la aventura. Apasionada de crear proyectos visualmente atractivos, funcionales y sobretodo accesibles para todos.",
    linkedin: "https://www.linkedin.com/in/mariana-sent%C3%ADes-575a07171/",
    photo: "Mariana.png",
  },
  {
    fullName: "Dania Ruiz",
    post: "Product Manager",
    quote: "Enamórate del problema, no de la solución",
    desc: "Apasionada por la creatividad y diseño, me entusiasma brindar soluciones optimas y visualmente atractivas para el usuario. Me encanta la fotografía y leer sobre el crecimiento personal.",
    linkedin: "https://www.linkedin.com/in/dania-ruiz-villa-479225206/",
    photo: "Dania.png",
  },
  {
    fullName: "Juan Martínez",
    post: "Software Developer",
    quote:
      "Todos los sueños se pueden hacer realidad si tienes el coraje de perseguirlos",
    desc: "Soy parte del equipo de TI en LaPieza.io, apasionado por las tecnologías y el poder desarrollar soluciones que mejoren la vida de las personas.",
    linkedin: "https://linkedin.com/in/juanmosorio",
    photo: "Juan.jpg",
  },
  {
    fullName: "José Carrera",
    post: "Web Solution Architect",
    quote:
      "Así como una coma cambia la frase, una simple actitud cambia una vida",
    desc: "Soy ingeniero en TI. Amo programar, leer, compartir mi poco conocimiento y formar parte de este maravilloso equipo.",
    linkedin:
      "https://www.linkedin.com/in/jos%C3%A9-miguel-carrera-pacheco-b17621194",
    photo: "Pach.jpg",
  },
  {
    fullName: "Miguel Ramos",
    post: "BD Developer",
    quote:
      "Equivocarse es parte de aprender, caer es parte de mejorar, equivocate y cae pero nunca te des por vencido",
    desc: "Egresado de la Universidad Autónoma de Nayarit en la carrera de Lic. en Sistemas Computacionales, entusiasta del arte cinematográfico, la naturaleza y los videojuegos, apasionado fan de la saga de Zelda y orgullo padre de familia. Me gusta mucho seguir superándome y afrontar nuevos retos",
    linkedin: "https://www.linkedin.com/in/miguelgperd",
    photo: "Miguel.jpeg",
  },
  /*{ fullName: "", post: "", quote: "", desc: "", linkedin: "", photo: "" },*/
];

export const furry = [
  { fullName: "Kaiser", post: "Wellbeing Manager", quote: "", desc: "Es como un oso de peluche de más de 60 kilos. ¡Ama que le rasquen la panza y lo saquen a caminar! Ah, también le encanta acostarse encima de ti, pero no lo dejes hacerlo o no podrás levantarte. Su super poder: sacarte sonrisas.", linkedin: "", photo: "kaiser.jpg" },
  { fullName: "Luna", post: "Happiness Manager", quote: "", desc: "La pequeña más tierna del oeste. Luna es encargada de generar felicidad en momentos de burnout. Le gustan las salchichas y el jamón. Alerta: Si dices pollo, puedes causar grandes expectativas en su día.", linkedin: "", photo: "luna.jpg" },
  { fullName: "Mar", post: "VP of Cuddles", quote: "", desc: "Ojo, si le das cariño a Mar, no te va a dejar en paz hasta que ella dicte que ya fue suficiente - lo cual es nunca.", linkedin: "", photo: "mar.jpg" },
  { fullName: "Balú", post: "Dog Developer", quote: "", desc: "Una compañera tierna, que siempre estará a tu lado haciéndote olvidar todo lo malo y recargando tu energía.", linkedin: "", photo: "Balu.jpeg" },
  { fullName: "Becky", post: "Dog Developer", quote: "", desc: "Soy un compañero súper travieso, nunca te dejaré en paz, siempre haciendo todo lo posible para tenerte feliz.", linkedin: "", photo: "Becky.jpeg" },
  { fullName: "Félix", post: "Canine Resources", quote: "Soy un poco miedoso, pero de a poquito me voy adentrando a nuevas aventuras. Tengo mucho pelo, no es que sea un gordito, así como también soy un poco enano. Me encargo de dar mucho amor y muchas lamidas a mi humana cuando las necesita. Respondo muy bien las órdenes de arriba, sobre todo cuando hay comida de por medio. Mi duraznito es 100% natural.", desc: "", linkedin: "", photo: "felix.jpg" },
  { fullName: "Theodore", post: "Canine Resources Intern", quote: "", desc: "Me acabo de sumar al team! Sigo con mucha atención las indicaciones de Félix, pero a veces suelo desordenar un poco todo el trabajo. Actualmente me estoy desempeñando en el área de moder todo lo que se me cruce. Todavia soy chiquito y lo único que crece es mi panza.", linkedin: "", photo: "theodore.jpg" },
  { fullName: "Chokis", post: "Barking Engineer", quote: "", desc: "Siempre puedes contar conmigo para dar un paseo aunque al regresar deberás darme una de mis galletas favoritas. Me encanta dormir en los sillones y correr por toda mi casa.", linkedin: "", photo: "chokis.jpg" },
/*  { fullName: "Kira", post: "", quote: "", desc: "", linkedin: "", photo: "Kira.jpg" },
  { fullName: "Coqueta", post: "", quote: "", desc: "", linkedin: "", photo: "coqueta.jpg" },
  { fullName: "Sashita", post: "", quote: "", desc: "", linkedin: "", photo: "sashita.jpg" },
  { fullName: "Jimbo", post: "", quote: "", desc: "", linkedin: "", photo: "jimbo.jpg" },
  { fullName: "Noah", post: "", quote: "", desc: "", linkedin: "", photo: "Noah.jpg" },
  { fullName: "Nara", post: "", quote: "", desc: "", linkedin: "", photo: "Nara.jpeg" },
  { fullName: "Hachi", post: "", quote: "", desc: "", linkedin: "", photo: "hachi.jpg" },
  { fullName: "Ziva y Maya", post: "", quote: "", desc: "", linkedin: "", photo: "ZivaMaya.jpg" },
  { fullName: "Elena y Salieri", post: "", quote: "", desc: "", linkedin: "", photo: "ElenaSalieri.jpeg" },

 */
];
